<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">结构调整</h2>
                <div class="RightDiv">
                    <div class="table-title">
                        <div>
                            <span>应用科室：</span>
                            <el-select v-model="depValu" placeholder="请选择应用科室" @change="typeDepChange(depValu)" clearable>
                                <el-option v-for="item in appDep" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="index-add">
                            <el-button type="primary" @click="creatStruc">创建结构调整策略</el-button>
                        </div>
                    </div>
                    </el-form>
                </div>
                <el-table :data="DeplisttableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="结构调整策略名称" align="center">
                    </el-table-column>
                    <el-table-column prop="departmentName" label="应用科室" align="center">
                    </el-table-column>
                    <el-table-column prop="startMonth" label="参考月份" align="center">
                        <template slot-scope="scope">
                            {{scope.row.year}}年{{scope.row.startMonth}}月
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间" align="center">
                    </el-table-column>

                    <el-table-column prop="remarks" label="备注" align="center" width="200px">
                        <template slot-scope="scope">
                            <div class="remakSty">{{scope.row.remarks}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" align="center">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="editJixiao(scope.row)"><i class="iconfont icon-caidanguanli"></i></el-button>
                            <el-button size="mini" type="danger" @click="deleteStr(scope.row)"><i class="iconfont el-icon-delete"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击操作制定结构调整策略 -->
        <el-dialog title="制定结构调整策略" top='1vh' :visible.sync="DepsalarydialogVisible" width="90%" :before-close="DepsalarydialogVisibleClose">
            <div class="depTit">
                <div class="topText">
                    <div class="titTop">
                        <div>结构调整策略名称：{{depOptions.name}}</div>
                        <div>应用科室：{{depOptions.departmentName}}</div>
                        <div>参考月份：{{depOptions.year}}年{{depOptions.startMonth}}月</div>
                    </div>
                    <div>说明：定制策略，仅显示标准医疗服务项目</div>
                </div>
                <el-select v-model="tabProperties" placeholder="请选择项目属性" @change="protabChange(tabProperties)" clearable>
                    <el-option v-for="item in proRange" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <el-table :data="SalaryListtableData" style="width: 95%; margin: 0 auto" border center>
                <el-table-column type="index" :index="indexMethod" label="序号" width="55" align="center">
                </el-table-column>
                <el-table-column prop="projectType" label="项目类型" align="center" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.projectType == 0">非收费项目</div>
                        <div v-else-if="scope.row.projectType == 1">收费项目</div>
                    </template>
                </el-table-column>
                <el-table-column prop="projectCode" label="项目编码" align="center" width="120">
                </el-table-column>
                <el-table-column prop="priceCode" label="收费编码" align="center" width="100">
                </el-table-column>
                <el-table-column prop="projectName" label="项目名称" align="center">
                </el-table-column>
                <el-table-column prop="currentPrice" label="现行价格" align="center">
                </el-table-column>
                <el-table-column prop="baseClass" label="基准项目比价系数" align="center">
                </el-table-column>
                <el-table-column prop="priceRatio" label="标化价值" align="center">
                </el-table-column>
                <el-table-column prop="standardizationCoefficient" label="标化系数" align="center">
                </el-table-column>
                <el-table-column prop="adjustmentCoefficient" label="调节系数" align="center">
                    <template slot-scope="scope">
                        <el-input class="inpCla" v-model="scope.row.adjustmentCoefficient" @change="coffeeChange(scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="strategicPrice" label="策略价" align="center">
                </el-table-column>
                <el-table-column prop="itemProperties" label="项目属性" align="center" width="170">
                    <template slot-scope="scope">
                        <el-select class="inpClass" v-model="scope.row.itemProperties" placeholder="请选择项目属性" @change="proChange(scope.row)">
                            <el-option v-for="item in proRange" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background @current-change="handleSalaryCurrentChange" :current-page="SalarypageNum" :page-size="SalarypageSize" layout="prev, pager, next" :total="Salarytotal">
            </el-pagination>
        </el-dialog>
        <!-- 创建结构调整策略 -->
        <el-dialog title="创建结构调整策略" :visible.sync="creadialogVisible" width="410px" :before-close="creadialogVisibleClose" top="10vh">
            <el-form ref="form" :rules="rules" :model="form" label-width="150px">
                <el-form-item label="结构调整策略名称：" prop="name">
                    <el-input v-model="form.name" placeholder="请填写结构调整策略名称"></el-input>
                </el-form-item>
                <el-form-item label="应用科室：" prop="departmentId">
                    <el-select v-model="form.departmentId" placeholder="请选择应用科室" clearable @change="typeFormDepChange(form.departmentId)">
                        <el-option v-for="item in appDep" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="参考月份：" prop="startMonth">
                    <el-date-picker v-model="form.startMonth" type="month" placeholder="请选择参考月份" format="yyyy-MM" value-format="yyyy-MM" :disabled=' monthDisa' :picker-options="pickerOptionsone">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="备注：" prop="remarks">
                    <el-input type="textarea" placeholder="请填写备注"  maxlength="250" show-word-limit v-model="form.remarks"></el-input>
                </el-form-item>
            </el-form>
            <div class="btnCen">
                <el-button v-if="cesuanClick" type="primary" :loading="cesuanClickKing" @click="submitForm('form')"             >创建</el-button>
                <el-button v-else type="info" :loading="cesuanClickKing"></el-button>
            </div>
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    data() {
        return {
            monthDisa: true,
            tabProperties: "",
            form: {
                name: "",
                departmentId: "",
                startMonth: "",
                remarks: "",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入结构调整策略名称",
                        trigger: "blur",
                    },
                ],
                departmentId: [
                    {
                        required: true,
                        message: "请选择应用科室",
                        trigger: "change",
                    },
                ],
                startMonth: [
                    {
                        required: true,
                        message: "请选择参考月份",
                        trigger: "change",
                    },
                ],
            },
            structuralAdjustmentId: "", //结构调整策略id
            // 参考月份
            pickerOptionsone: {
                disabledDate: (time) => {
                    if (this.form.departmentId) {
                        const year = time.getFullYear();
                        let month = time.getMonth() + 1;
                        if (month < 10) month = "0" + month;
                        const ym = year + "-" + month;
                        // console.log(ym)
                        return !this.creactMonthArr.includes(ym);
                    }
                },
            },
            // 项目属性
            proRange: [
                {
                    value: 1,
                    label: "优势项目",
                },
                {
                    value: 2,
                    label: "潜力项目",
                },
                {
                    value: 3,
                    label: "劣势项目",
                },
            ],
            options: [
                {
                    value: "选项1",
                    label: "黄金糕",
                },
                {
                    value: "选项2",
                    label: "双皮奶",
                },
            ],
            // 创建结构调整策略的创建按钮
            cesuanClick: true,
            cesuanClickKing: false,
            value: "",
            // 制定结构调整策略
            DepsalarydialogVisible: false,
            // 制定结构调整策略表格数据
            SalaryListtableData: [],
            // 创建结构调整策略
            creadialogVisible: false,
            // 总页数
            Salarytotal: 1,
            // 每页页数
            SalarypageNum: 1,
            // 每页显示条数
            SalarypageSize: 10,
            // 表格数据
            DeplisttableData: [],
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            //这条数据id
            id: "",
            // 总页数
            total: 1,
            // 每页页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 科室id
            departmentId: window.sessionStorage.getItem("departmentId"),
            appDep: [], //应用科室列表
            depValu: "", //应用科室的值
            depOptions: {},
        };
    },
    watch: {
        "form.departmentId": {
            handler(newName, oldName) {
                this.form.startMonth = "";
            },
            deep: true,
        },
    },
    created() {
        this.strList();
        // 选择科室
        this.getDepartmentNotPage();
    },
    methods: {
        async getYearAndMonth(KeshiDataListvalue) {
            this.creactMonthArr = [];
            let data = {
                departmentId: KeshiDataListvalue, //科室id
            };
            let { data: res } = await this.$axios.getYearAndMonth(data);
            // console.log(res)
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取科室模拟测算列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                let arr = [];
                for (let i = 0; i < res.data.length; i++) {
                    arr.push({ year: res.data[i].year, month: [] });
                    for (
                        let j = 0;
                        j < res.data[i].departmetMonthDetails.length;
                        j++
                    ) {
                        // console.log(res.data[i].departmetMonthDetails[j].month);
                        if (res.data[i].departmetMonthDetails[j].month < 10) {
                            arr[i].month.push({
                                month:
                                    res.data[i].year +
                                    "-" +
                                    "0" +
                                    res.data[i].departmetMonthDetails[j].month,
                            });
                        } else {
                            arr[i].month.push({
                                month:
                                    res.data[i].year +
                                    "-" +
                                    res.data[i].departmetMonthDetails[j].month,
                            });
                        }
                    }
                }
                // console.log(arr)
                for (let i = 0; i < arr.length; i++) {
                    // console.log(...arr[i].month)
                    for (let j = 0; j < arr[i].month.length; j++) {
                        // console.log(arr[i].month[j].month)
                        this.creactMonthArr.push(arr[i].month[j].month);
                        // console.log(this.creactMonthArr);
                    }
                }
            }
        },
        //设置表格翻页的序号递增
        indexMethod(index) {
            let SalarypageNum = this.SalarypageNum; // 当前页码
            let SalarypageSize = this.SalarypageSize; // 每页条数
            return index + 1 + (SalarypageNum - 1) * SalarypageSize; // 返回表格序号
        },
        // 调节系数
        async coffeeChange(val) {
            // console.log(val)
            let data = _qs.stringify({
                id: val.id, //策略详情id
                adjustmentCoefficient: val.adjustmentCoefficient, //调节系数
            });
            let { data: res } = await this.$axios.updateAdjustmentCoefficient(
                data
            );
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.$message({
                    message: res.msg,
                    type: "success",
                });
                this.strAdjList();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 表格外面的项目属性
        protabChange(row) {
            this.tabProperties = row;
            this.strAdjList();
        },
        // 选择项目属性
        async proChange(row) {
            let data = _qs.stringify({
                id: row.id, //策略详情id
                itemProperties: row.itemProperties, //项目属性（1优势项目 2潜力项目 3劣势项目）
            });
            let { data: res } = await this.$axios.updateItemProperties(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.$message({
                    message: res.msg,
                    type: "success",
                });
                this.strAdjList();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 选择应用科室
        typeDepChange(val) {
            this.depValu = val;
            this.pageNum = 1;
            this.strList();
        },
        // 新建的应用科室
        typeFormDepChange(val) {
            this.form.departmentId = val;
            if (val) {
                this.monthDisa = false;
                // 2022-4-19修改 获取年份和月份
                this.getYearAndMonth(val);
            } else {
                this.monthDisa = true;
            }
        },
        // 获取医院所属科室列表
        async getDepartmentNotPage() {
            let data = {
                hospitalId: this.hospitalId,
            };
            let { data: res } = await this.$axios.getDepartmentNotPage(data);
            // console.log(res)
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.appDep = res.data;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 时间格式化
        timestampToTime(timestamp) {
            var date = new Date(timestamp);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            return y + "-" + m;
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.cesuanClick = false;
                    this.cesuanClickKing = true;
                    let month;
                    if (this.form.startMonth) {
                        month = this.timestampToTime(this.form.startMonth);
                    }
                    let data = _qs.stringify({
                        name: this.form.name, //名称
                        year: month.split("-")[0], //年份
                        startMonth: month.split("-")[1], //月份
                        departmentId: this.form.departmentId, //科室id
                        remarks: this.form.remarks, //备注
                    });
                    let { data: res } = await this.$axios.strAdd(data);
                    // console.log(res);
                    this.cesuanClick = true;
                    this.cesuanClickKing = false;
                    if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 200) {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        this.strList();
                        this.creadialogVisibleClose();
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        creadialogVisibleClose() {
            this.creadialogVisible = false;
            this.monthDisa = true;
            this.$refs.form.resetFields();
            this.form = {
                name: "",
                departmentId: "",
                startMonth: "",
                remarks: "",
            };
        },
        // 创建结构调整策略
        creatStruc() {
            this.creadialogVisible = true;
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.strList();
        },
        //   获取医院所属科室列表
        async strList() {
            let data = {
                departmentId: this.depValu, //科室id
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示数量
                yearId: "", //科室年份工作量id
                monthId: "", //科室月份工作量id
            };
            let { data: res } = await this.$axios.strList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.DeplisttableData = res.rows;
                this.total = res.total;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 获取科室薪酬数据列表的分页
        handleSalaryCurrentChange(val) {
            this.SalarypageNum = val;
            this.strAdjList();
        },
        // 点击删除
        async deleteStr(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                id: row.id,
            });
            let { data: res } = await this.$axios.deleteList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.$message({
                    message: res.msg,
                    type: "success",
                });
                this.strList();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 点击操作
        editJixiao(row) {
            this.depOptions = row;
            this.structuralAdjustmentId = row.id;
            this.DepsalarydialogVisible = true;
            this.strAdjList();
        },
        // 结构调整策略详情列表
        async strAdjList() {
            let data = {
                pageNum: this.SalarypageNum,
                pageSize: this.SalarypageSize,
                structuralAdjustmentId: this.structuralAdjustmentId, //结构调整策略id
                itemProperties: this.tabProperties, //项目属性（1优势项目 2潜力项目 3劣势项目）
            };
            let { data: res } = await this.$axios.strAdjList(data);
            // console.log(res)
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.SalaryListtableData = res.rows;
                this.Salarytotal = res.total;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        DepsalarydialogVisibleClose() {
            this.DepsalarydialogVisible = false;
            this.strList();
        },
    },
};
</script>

<style lang="less" scoped>
.table-title {
    display: flex;
    margin-bottom: 20px;
    justify-content: right;
}

.index-add {
    margin-left: 10px;
}

.hosform {
    display: flex;
    .hosdanwei {
        margin-left: 10px;
    }
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 30px;
}

/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}

.remaTex {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.depTit {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-content: center;
    div {
        margin-bottom: 5px;
    }
}

/deep/ .el-dialog__body {
    text-align: inherit;
    .btnCen {
        width: 100%;
        text-align: center;

        .el-button {
            width: 150px;
        }
    }
}

.topText {
    font-weight: bold;
    font-size: 17px;
}

.titTop {
    display: flex;
    div {
        margin-right: 30px;
    }
}

/deep/ .el-input .el-input__inner {
    width: 220px;
}

/deep/ .inpCla .el-input__inner {
    width: 100px !important;
}

/deep/ .el-textarea__inner,
/deep/ .el-textarea {
    width: 220px !important;
}

/deep/ .inpClass .el-input__inner {
    width: 130px !important;
}
</style>
